import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AmazonAffiliate from "../adverts/AmazonAffiliate";
// import AdvertsBlock from "../adverts/AdvertBlock";
import AdvertOwnBlock from "../blocks/AdvertOwnBlock";
// import BacklinkProductBlock from "../blocks/BacklinkProductBlock";
import LastBlogPosts from "../posts/LastBlogPosts";
import NewsletterSubscribe from "./NewsletterSubscribe";
import { history } from '../..';
import { useStore } from "../../app/stores/store";
import SearchBox from "./SearchBox";

export default function HomePage() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    const { blogStore } = useStore();
    const { setPredicate } = blogStore;

    useEffect(() => {        
            trackPageView({
                documentTitle: 'Homepage'
            });
    }, [trackPageView])

    function handleSearch(search: string) {
        // console.log('SUCHBEGRIFF', search);
        setPredicate('searchParam', search);
        history.push("/artikel")
    }

    return (
        <Fragment>
            <Helmet>
                <title>Master Online Marketing</title>      
                <meta name="description" content="Das Portal zum Thema Online-Marketing. Mehr Kunden, mehr Umsatz, mehr Erfolg durch Online-Marketing" />
                <meta name="keywords" content="online marketing, marketing, werbung, vermarktung, mehr kunden, mehr umsatz" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Row>
            <Col md={9}>
            <h1>Online Erfolgreich Vermarkten</h1>
            <AdvertOwnBlock
                title='Mehr Kunden, mehr Umsatz, mehr Erfolg durch Online Marketing'
                content='<p>Sie haben ein tolles Produkt, eine nutzbringende Dienstleistung aber keiner besucht Ihre Webseite? Ihnen fehlen die Kunden und damit auch der Umsatz, um Ihr Online-Business weiter nach vorne zu bringen?</p><p>Die Entwicklung von Produkten und Dienstleistungen ist eine Seite der Medaille- die erfolgreiche Vermarktung dieser eine ganz andere Disziplin. Gerade im Online-Bereich existieren vielfältige Möglichkeiten, die wir in diesem Portal vorstellen.</p>'
                image='/banners/master-online-marketing-banner.png'
                imageTarget=''
                targets={[
                    // { linkText: 'Was ist Online Marketing?', linkTarget: '/was-ist-online-marketing' },
                    // { linkText: 'Gesund und Fit durch Fasten', linkTarget: '/erfolgreich-im-internet' },
                    // { linkText: 'Backlinks aufbauen', linkTarget: '/ihre-backlinks' },
                    // { linkText: 'Bewerben Sie Ihre Produkte', linkTarget: '/ihre-werbung' },
                ]}
            />

            {/* <AdvertsBlock advertCount={1} advertType={1} /> */}

            <LastBlogPosts articleCount={5} />

            </Col>
            <Col md={3}>
                <SearchBox updateSearch={handleSearch} />
                
                <NewsletterSubscribe />

                <AmazonAffiliate
                    image='https://m.media-amazon.com/images/I/41yu7s9w+eL.jpg'
                    text='Affiliate-, Influencer-, Content- und E-Mail-Marketing, Google Ads, SEO, Social Media, Facebook-Werbung'
                    link='https://www.amazon.de/dp/B07NZ9Z2LL/ref=as_li_ss_tl?dchild=1&keywords=online+marketing&qid=1624364799&sr=8-5&linkCode=ll1&tag=kayomo-21&linkId=24a753dbab70767b9951014ac18bf2dc&language=de_DE'
                    title='Praxiswissen Online-Marketing'
                />

                {/* <BacklinkProductBlock /> */}

                {/* <AdvertsBlock advertCount={1} advertType={2} /> */}
            </Col>
            </Row>
        </Fragment>
    )
}